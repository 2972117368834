import { ReelSet } from '../global';
import { Features, Icon, LineSet } from '../slotMachine/d';

export declare interface IConfig {
  isAuthorized: boolean;
  isLeftHandMode: boolean;
  isMobile: boolean;
  isTurboSpin: boolean;
  isSoundOn: boolean;
  isSpinInProgress: boolean;
  isEnabledSpaceSpin: boolean;
  isSlotBusy: boolean;
  isOpenAutoplayModal: boolean;
  isOpenBetSettingsModal: boolean;
  isSlotStopped: boolean;
  isShowSuspendedToast: boolean;
  isMiniPayTable: boolean;
  isOpenMenuModal: {
    isOpen: boolean;
    type: 'menu' | 'info';
  };
  [key: string]: boolean | unknown;
}

export type DataGQl = {
  storeFieldName: string;
};

export declare interface ISlotConfig {
  id: string;
  lineSet: LineSet;
  lines: number[];
  clientSettings: {
    coinAmounts: {
      default: number[];
      quick: number[];
    };
    coinValues: {
      code: string;
      variants: number[];
    }[];
    features: Features[];
    autoplay: {
      options: number[];
      conditions: {
        stopOnAnyWin: {
          enabled: boolean;
        };
        stopIfFeatureIsWon: {
          enabled: boolean;
        };
        stopIfSingleWinExceeds: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceDecreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceIncreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
      };
    };
  };
  sessionId: string;
  reels: ReelSet[];
  winLines: number[][];
  icons: Icon[];
  isBuyFeatureEnabled: boolean;
}

export declare interface IBetSettings {
  minBet: number;
  maxBet: number;
  bets: number[];
  quick: number[];
  numberOfLines: number;
  coinValue: number;
  minBetCount: number;
  maxBetCount: number;
}

export declare interface ISlotHistory {
  gameId: string;
  date: string | number;
  bet: number | string;
  win: number | string;
  cursor: string;
  [key: string]: string | number;
}
export declare interface IBet {
  id: string;
  coinAmount: number;
  coinValue: number;
  result: {
    reelPositions: number[];
    winCoinAmount: number;
  };
  userBonus?: {
    bonusId: string;
  };
  reelSetId: string;
  createdAt: string;
  updatedAt: string;
}
export declare interface ISlotHistoryData {
  edges: {
    cursor: string;
    node: IBet;
  }[];
  pageInfo: {
    count: number;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

export declare interface IStressful {
  show: boolean;
  type: 'network' | 'balance' | 'session' | 'none' | 'slotMachine' | 'blocking' | 'info';
  title?: string;
  message: string;
  callback?: () => void;
}

export enum ReelSetType {
  DEFAULT,
  BONUS,
}
